const actions = {
    ADD_PERIMITER: "ADD_PERIMITER",
    SET_ACTIVE_PERIMITER: "SET_ACTIVE_PERIMITER",
    DELETE_PERIMITER: "DELETE_PERIMITER",
    CLEAR_PERIMITERS: "CLEAR_PERIMITERS",
    GET_PERIMITER: "GET_PERIMITER",
    SET_PERIMITERS: "SET_PERIMITERS",
    TOGGLE_SELECTED: "TOGGLE_SELECTED",
    SET_LAST_SELECTED: "SET_LAST_SELECTED",
    SET_SELECTED_GROUP: "SET_SELECTED_GROUP",
    ADD_USER_MARKERS: "ADD_USER_MARKERS",

    addPerimiter: (perimeter) => ({
        type: actions.ADD_PERIMITER,
        payload: perimeter
    }),
    setActivePerimiter: (perimeter) => ({
        type: actions.SET_ACTIVE_PERIMITER,
        payload: perimeter
    }),
    deletePerimiter: (perimeter) => ({
        type: actions.DELETE_PERIMITER,
        payload: perimeter
    }),
    clearPerimiters: (perimeter) => ({
        type: actions.DELETE_PERIMITER,
        payload: perimeter
    }),
    setPerimiters: (perimitersArray) => ({
        type: actions.SET_PERIMITERS,
        payload: perimitersArray
    }),
    toggleSelected: (perimeter) => ({
        type: actions.TOGGLE_SELECTED,
        payload: perimeter
    }),
    setLastSelected: (perimeter) => ({
        type: actions.SET_LAST_SELECTED,
        payload: perimeter
    }),
    setSelectedGroup: (group) => ({
        type: actions.SET_SELECTED_GROUP,
        payload: group
    }),
    addUserMarkers: (data) => ({
        type: actions.SET_SELECTED_GROUP,
        payload: data
    }),
};
export default actions;
