import React, { lazy, Suspense, useEffect } from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { useSelector } from "react-redux";

import ErrorBoundary from "./ErrorBoundary";
import { PUBLIC_ROUTE } from "./route.constants";
import Loader from "@iso/components/utility/loader";

import config from "@iso/config/site.config";
import ForeignRedirect from "./components/Fenomy/ForeignRedirect";

const Dashboard = lazy(() => import("./containers/Dashboard/Dashboard"));
const Success = lazy(() => import("./containers/Invest/Payeer/Success.js"));
const Fail = lazy(() => import("./containers/Invest/Payeer/Fail.js"));
const Status = lazy(() => import("./containers/Invest/Payeer/Status.js"));
const Page404 = lazy(() => import("./containers/Pages/404/404"));

const publicRoutes = [
  {
    path: PUBLIC_ROUTE.LANDING,
    exact: true,
    component: lazy(() => import("@iso/containers/Pages/Landing/Landing")),
  },
  {
    path: PUBLIC_ROUTE.EMAIL_CONFIRM,
    exact: true,
    component: lazy(() => import("@iso/containers/ConfirmEmail")),
  },
  {
    path: PUBLIC_ROUTE.LANDING_INNER,
    exact: false,
    component: lazy(() => import("@iso/containers/Pages/Landing/Landing")),
  },
  {
    path: PUBLIC_ROUTE.PAGE_404,
    component: lazy(() => import("@iso/containers/Pages/404/404")),
  }

];
function PrivateRoute({ children, ...rest }) {
  const isAuthorized = useSelector((state) => state.Auth.isAuthorized);
  const lang = useSelector((state) => state.LanguageSwitcher);

  useEffect(() => {
    if (!isAuthorized) {
      let url = config.autorizeUrl + '&lng=' + lang.language.locale;
      document.location.href = url;
      return null;
    }
  });

  return (
    <Route
      {...rest}
      render={({ location }) => {
        let url = config.autorizeUrl + '&lng=' + lang.language.locale;
        return isAuthorized ? children : null;
      }

      }
    />
  );
}

export default function Routes() {
  let langs = [
    'en', 'ru', 'zh', 'es', 'pt'
  ]

  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader />}>
        <Router>
          <Switch>
            {langs.map((lang, langIndex) => (
              publicRoutes.map((route, index) => (
                <Route key={langIndex + '_' + index} path={lang === 'en' ? route.path : '/' + lang + route.path} exact={route.exact}>
                  <route.component />
                </Route>
              ))
            ))}
            <PrivateRoute path="/dashboard">
              <Dashboard />
            </PrivateRoute>
            <Route path="/payeer/success">
              <Success />
            </Route>
            <Route path="/payeer/fail">
              <Fail />
            </Route>
            <Route path="/payeer/status">
              <Status />
            </Route>
            <Route path="*">
              <Page404 />
            </Route>
          </Switch>
        </Router>
      </Suspense>
    </ErrorBoundary>
  );
}
