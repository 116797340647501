import React from 'react';
import ReactNotifications from 'react-browser-notifications';

export default class BrowserNotification extends React.Component {
  constructor(props) {
    super(props);
    this.showNotifications = this.showNotifications.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.state = {
      title: props.notify ? props.notify.title  : '',
      text: props.notify ? props.notify.text  : '',
    }

  }

  showNotifications() {
    if(this.n.supported()) this.n.show();
  }

  handleClick(event) {
    window.focus();
    this.n.close(event.target.tag);
  }


  componentWillReceiveProps(nextProps) {
    this.setState({
      title: nextProps.notify ? nextProps.notify.title  : '',
      text: nextProps.notify ? nextProps.notify.text  : ''
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevState.text !== this.state.text){
      this.showNotifications();
    }
  }

  render() {
    if(!this.state.title){
      return (<></>);
    }
    return (
        <div>

          <ReactNotifications
              onRef={ref => (this.n = ref)} // Required
              title={this.state.title} // Required
              body={this.state.text}
              icon="devices-logo.png"
          />

        </div>
    )
  }
}
