import antdSA from 'antd/lib/locale-provider/ca_ES';
import saMessages from '../locales/es_ES.json';
import saMessagesFront from "../locales/es_ES_front.json";

const saLang = {
  messages: {
    ...saMessages, ...saMessagesFront,
  },
  antd: antdSA,
  locale: 'es',
};
export default saLang;
