const actions = {
    SET_USER: "SET_USER",
    SET_USERS_LIST: "SET_USERS_LIST",
    DELETE_SINGLE_USERS_LIST: "DELETE_SINGLE_USERS_LIST",
    TOGGLE_DRAWER: "TOGGLE_DRAWER",
    SET_DISTANCE: "SET_DISTANCE",
    SET_USER_COORDS: "SET_USER_COORDS",

    setUser: (user) => {
        return {
            type: actions.SET_USER,
            user: user
        }
    },

    set_users_list: (users) => {
        return {
            type: actions.SET_USERS_LIST,
            users: users
        }
    },
    delete_from_users_list: (user) => {
        return {
            type: actions.DELETE_SINGLE_USERS_LIST,
            user: user
        }
    },
    toggleDrawer: () => {
        return {
            type: actions.TOGGLE_DRAWER,
        }
    },
    setDistance: (payload) => {
        return {
            type: actions.SET_DISTANCE,
            payload: payload
        }
    },
    setUserCoords: (payload) => {
        return {
            type: actions.SET_USER_COORDS,
            payload: payload
        }
    },

};

export default actions;
