import api from "@iso/lib/api/rest";
import langActions from "../languageSwitcher/actions";
import { getCurrentLanguage } from "../languageSwitcher/config";

const actions = {
  AUTH_CHECK: "AUTH_CHECK",
  AUTH_BEGIN: "AUTH_BEGIN",
  AUTH_SUCCESS: "AUTH_SUCCESS",
  AUTH_ERROR: "AUTH_ERROR",
  LOGOUT: "LOGOUT",

  authCheck: () => {
    return async (dispatch) => {
      dispatch(actions.authBegin());
      let authResult = await api.q("/whoami", {}, false, false, false, true, 'POST', 'cors', "/api/v1");
      if (authResult) {
        let accessMap = {};
        let classes = await api.q("/class", {}, { toObject: true });
        for (let classId in classes) {
          let classItem = classes[classId];
          let access = classItem.access;
          accessMap[classItem.code] = access;
        }

        if (authResult.locale && false) {
          const localeCode = authResult.locale.code;
          const language = getCurrentLanguage(localeCode);
          if (language && localeCode) {
            dispatch(langActions.changeLanguageSuccess(language.languageId));
          }
        }

        dispatch(actions.authSuccess(authResult, accessMap));
      } else {
        dispatch(actions.authError());
      }
    };
  },

  authSuccess: (authResult, accessMap) => {
    return { type: actions.AUTH_SUCCESS, authResult, accessMap };
  },

  authBegin: () => {
    return { type: actions.AUTH_BEGIN };
  },

  authError: (errorMessage = "") => {
    return { type: actions.AUTH_ERROR, errorMessage };
  },

  logoutSuccess: () => ({
    type: actions.LOGOUT,
  }),

  logout: () => {
    return async (dispatch) => {
      await api.q("/sign/out");
      dispatch(actions.logoutSuccess());
    };
  },
};
export default actions;
