import antdRu from "antd/lib/locale-provider/ru_RU";
import ruMessages from "../locales/ru_RU.json";
import ruMessagesFront from "../locales/ru_RU_front.json";

const RuLang = {
  messages: {
    ...ruMessages, ...ruMessagesFront
  },
  antd: antdRu,
  locale: "ru-RU",
};
export default RuLang;
