import antdEn from 'antd/lib/locale-provider/en_US';
import enMessages from '../locales/en_US.json';
import enMessagesFront from '../locales/en_US_front.json';

const EnLang = {
  messages: {
    ...enMessages, ...enMessagesFront
  },
  antd: antdEn,
  locale: 'en-US',
};
export default EnLang;
