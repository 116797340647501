import actions from "./actions";

const initState = {
    skills: {
        values: []
    },
    tasks: {
        accept: {
            checkbox: [],
            raduis: 1
        },
        send: {
            checkbox: [],
            raduis: 1
        },
    },
    alarms: {
        accept: {
            checkbox: [],
            raduis: 1
        },
        send: {
            checkbox: [],
            raduis: 1
        },
    },
    groups: {
        values: []
    }
};
export default function settingsReducer(state = initState, action) {
    // console.log(action);
    switch (action.type) {
        case actions.TASKS_SAVE:
            return {
                ...state,
                isPending: true,
                error: false,
                success: false,
            };
        case actions.PROFILE_SAVE_BEGIN:
            return {
                ...state,
                isPending: true,
                error: false,
                success: false,
            };
        case actions.PROFILE_SAVE_SUCCESS:
            return {
                ...state,
                isPending: false,
                error: false,
                success: true,
            };
        case actions.PROFILE_SAVE_ERROR:
            return {
                ...state,
                isPending: false,
                error: true,
                success: false,
            };
        default:
            return state;
    }
}
