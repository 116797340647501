import React, {useCallback, useEffect, useState} from "react";
import { ConfigProvider } from "antd";
import { IntlProvider, createIntl, createIntlCache} from "react-intl";
import { useSelector } from "react-redux";
import { ThemeProvider } from "styled-components";
import { ThroughProvider } from "react-through";
import themes from "@iso/config/theme/theme.config";
import AppLocale from "@iso/config/translation";
import Loader from "@iso/components/utility/loader";
import Context from "./Context";
import useWebSocket from 'react-use-websocket';
import accessToken from "@iso/lib/api/token";
import api from "@iso/lib/api/rest";
import BrowserNotification from "./components/BrowserNotification";

export default function AppProviderInner({Auth, locale, children}) {


  const { themeName } = useSelector(
    (state) => state.ThemeSwitcher.changeThemes
  );


  const socketUrl = 'wss://fenomy.cc/session/' + Auth.raw.session.code;

  const {
    sendMessage,
    sendJsonMessage,
    lastMessage,
    lastJsonMessage,
    readyState,
    getWebSocket,
  } = useWebSocket(socketUrl, {
    onOpen: (e) => {
      console.log("connected websocket main component", Auth);

      let uuId = api.getUUid(Auth.raw.session.code);
      sendMessage('{"t":0,"u":"'+uuId+'","p":{"token": "'+accessToken.get()+'"}}')

      setTimeout(function () {
        let uuId = api.getUUid(Auth.raw.session.code);
        let id = api.getUUid(Auth.raw.session.code+accessToken.get());

        sendMessage('{"t":"2","u":"'+uuId+'","a":"/observer/subscribe",'+
            '"messageType":"2","path":"/observer/subscribe", "id":"'+id+'", '+
            '"p":[{"publisher":"notify","params":{"type":"object"},"filter":{"entities":'+
            '["object","document","address","client","device","calendar","category","model","program","scheduler",'+
            '"vendor","alarm","incident","interaction","invitation","transaction","skill"]}},{"publisher":"geo"},{"publisher":"notice"}]}'
        );
      },1000);
    },
    //Will attempt to reconnect on all close events, such as server shutting down
    shouldReconnect: (closeEvent) => true,
  });


  const currentAppLocale = AppLocale[locale];
  const cache = createIntlCache();
  const intl = createIntl({
    locale: locale,
    messages: currentAppLocale.messages
  }, cache);

  let helpdeskURL = '';

  if(locale === 'ru'){
    helpdeskURL = 'https://helpdesk-ru.' + process.env.REACT_APP_DOMAIN;
  }else{
    helpdeskURL = 'https://helpdesk.' + process.env.REACT_APP_DOMAIN;
  }

  const cx = {
    api, lastJsonMessage, helpdeskURL: helpdeskURL
  };

  return (
    <Context.Provider value={cx}>
      <ConfigProvider locale={currentAppLocale.antd}>
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}
        >
          <ThemeProvider theme={themes[themeName]}>
            <ThroughProvider>
              {Auth.isPending ? <Loader /> : children}
            </ThroughProvider>
          </ThemeProvider>
        </IntlProvider>
      </ConfigProvider>
    </Context.Provider>
  );
}
