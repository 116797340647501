import actions from "./actions";

const defaultState = {
    activeGroups: [],
    currentGroup: {},
    members: [],
    messages: [],
    userStatus: false,
}


export default function relationReducer(state = defaultState, action) {

    switch (action.type) {
        case actions.GET_ACTIVE_GROUPS:
            return {
                ...state,
                activeGroups: action.groups_list
            };
        case actions.SET_CURRENT_GROUP:
            return {
                ...state,
                currentGroup: action.group
            };
        case actions.SET_MEMBERS:
            return {
                ...state,
                members: action.members
            };
        case actions.SET_MESSAGES:
            return {
                ...state,
                messages: action.messages
            };
        case actions.SET_CURRENT_USER_STATUS:
            return {
                ...state,
                userStatus: action.status
            };
       
        default:
            return state;
    }
}