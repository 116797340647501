import { getCurrentLanguage } from "./config";
import api from "@iso/lib/api/rest";
// import moment from "moment";
//import "moment/locale/ru";

const actions = {
  CHANGE_LANGUAGE: "CHANGE_LANGUAGE",
  ACTIVATE_LANG_MODAL: "ACTIVATE_LANG_MODAL",
  switchActivation: () => ({
    type: actions.ACTIVATE_LANG_MODAL,
  }),

  changeLanguage: (language, isUserEvent = false) => {
    return async (dispatch) => {
      const lang = getCurrentLanguage(language);
      await api.q("/session/set/locale", { id: null, code: lang.locale });
      dispatch(actions.changeLanguageSuccess(language, isUserEvent));
    };
  },

  changeLanguageSuccess: (language, isUserEvent = false) => {
    const lang = getCurrentLanguage(language);
    // localStorage.setItem("feDefaultLang", language);
    if (isUserEvent) {
      if (lang.locale === 'en') {
        let pathData = window.location.pathname.split('/');
        let path = '';
        for (let i in pathData) {
          if (i > 1) {
            path += '/' + pathData[i];
          }
        }
        window.location.href = path;
      } else {
        if (window.location.href.indexOf("dashboard") < 0) {
          let pathName = window.location.pathname.replace('/ru', '').replace('/zh', '').replace('/es', '').replace('/pt', '');
          window.location.href = '/' + lang.locale + pathName;
        }
      }

    }
    return {
      type: actions.CHANGE_LANGUAGE,
      language: lang,
    };
  },
};
export default actions;
