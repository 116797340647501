import actions from "./actions";
import _ from 'lodash';

const initState = {
    // еще под вопросом первое свойство, пока что он заменяется selected
    active: {},
    perimiters: [],
    selected: [],
    lastSelected: {},
    selectedGroup: {},
    group_users: []
};

export default function perimitersReducer(state = initState, action) {
    switch (action.type) {
        case actions.ADD_PERIMITER:
            return {
                ...state,
                perimiters: [
                    ...state.perimiters,
                    action.payload
                ],
                active: action.payload
            };
        case actions.SET_ACTIVE_PERIMITER:
            return {
                ...state,
                active: action.payload
            };
        case actions.DELETE_PERIMITER:
            return {
                ...state,
                perimiters: state.perimiters.filter(single => single.name != action.payload.name),
                active: action.payload.name === state.active.name ? {} : state.active
            };
        case actions.CLEAR_PERIMITERS: {
            return initState;
        }
        case actions.TOGGLE_SELECTED: {
            return {
                ...state,
                selected: _.xor(state.selected, [action.payload])
            }
        }
        case actions.SET_PERIMITERS: {
            return {
                ...state,
                perimiters: _.flatten(action.payload)
            }
        }
        case actions.SET_LAST_SELECTED: {
            return {
                ...state,
                lastSelected: action.payload
            }
        }
        case actions.SET_SELECTED_GROUP: {
            return {
                ...state,
                selectedGroup: action.payload
            }
        }
        case actions.ADD_USER_MARKERS: {
            return {
                ...state,
                group_users: [...state.group_users, action.payload]
            }
        }
        default:
            return state;
    }
}
