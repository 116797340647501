export default {
  siteName: "FENOMY",
  siteIcon: "/logo-inverse.png",
  footerText: `Fenomy @ ${new Date().getFullYear()}`,
  enableAnimatedRoute: false,
  autorizeUrl: process.env.REACT_APP_AUTH_URL,
  siteDomain: process.env.REACT_APP_DOMAIN,
  apiDomain: process.env.REACT_APP_HOST,
  siteRefHost: process.env.REACT_APP_REF_HOST,
  siteRefCode: "FDwd32016;^sdd",
  dashboard: "/dashboard",
  admin: "/dashboard/admin",
  oAuth2RedirectUrl: process.env.REACT_APP_HOST_CODE,
  clientId: "web-fenomy.com",
  clientSecret: "ziXugsqYWG0J7w0PRF6ztbXD"
};
