import config from "./config";
import accessToken from "./token";
import getChildren from "@iso/lib/helpers/getChildren";

import NProgress from "nprogress";
import "nprogress/nprogress.css";
import message from "@iso/components/Feedback/Message";
import Sha256 from "@iso/lib/helpers/Sha256.js";

const rest = {
  q: async (
    path,
    rawBody = {},
    normalizeParams = false,
    forceToken = false,
    skipToken = false,
    isJSON = true,
    method = 'POST',
    mode = 'cors',
    apiPath = ''
  ) => {
    const token = forceToken ? forceToken : accessToken.get();

    if (!token && !skipToken) {
      return rest.error("No token present");
    }
    if (!path) {
      return rest.error("No path specified");
    }

    //Prepare request
    NProgress.start();
    let body = isJSON ? JSON.stringify(rawBody) : rawBody;
    let headers = new Headers();
    if(isJSON){
      headers.append("Content-Type", "application/json");
    }
    if (!skipToken) {
      headers.append("Authorization", `Bearer ${token}`);
      //console.log("--> API ", path, headers, skipToken, `Bearer ${token}`);
    }

    if(!apiPath){
      //Make request
      apiPath = path.includes("oauth") ? "" : config.apiPath;
    }
    const url = `${config.apiDomain}${apiPath}${path}`;
    if (config.debug) {
      //console.log("--> API ", path, body, headers);
    }
    let response;

    if(method === 'GET'){
      response = await fetch(url, {
        headers,
        referrerPolicy: "no-referrer",
        mode: mode
      });
    }else{
      response = await fetch(url, {
        method: method,
        headers,
        body,
        referrerPolicy: "no-referrer",
        mode: mode,
        cache: "no-store",
      });
    }
    let json = await response.json();
    if (config.debug) console.log(`API <-- ${path}`, json);
    NProgress.done();

    if (json) {
      const normalize = normalizeParams || {};

      //Handle errors
      let errMessage = "";
      if (json.hasOwnProperty("error"))
        errMessage = json.error["message"] || "";

      //More logig should perform here
      if (response.status === 403) {
        errMessage = `403 ${errMessage}`;
      }
      if (response.status === 401) {
        errMessage = `401 ${errMessage}`;
      }
      if (response.status === 400) {
        errMessage = `400 ${errMessage}`;
      }

      if (json.hasOwnProperty("error")) {
        rest.error(errMessage);
        if (normalize.toObject || normalize.toTree) return {};
        if (normalize.toArray) return [];
        if (normalize.exact) return json;
        return false;
      }

      //Normalize & return result
      if (!normalizeParams) {
        return json; //No normaliztion rqrd
      }

      //To array...
      let items = Array.isArray(json) ? json : [json];

      //Sort...
      if (normalize.sortBy) {
        items.sort((a, b) => {
          return a[normalize.sortBy] - b[normalize.sortBy];
        });
      }

      //To plain keyed object
      if (normalize.toObject) {
        let result = {};
        const keyField = normalize.keyField || "id";
        items.forEach((item, index) => {
          let key = item[keyField] || index;
          result[key] = item;
        });
        return result;
      }

      if (normalize.toTree) {
        let result = [];
        let itemsIDs = [];

        if (normalize.defaultTreeItems) {
          items = items.concat(normalize.defaultTreeItems);
        }

        items.forEach((item) => {
          itemsIDs.push(item.id);
        });

        const parentKey = normalize.parentKey || "parent";
        items.forEach((item) => {
          if (
            !item[parentKey] ||
            item.level === 0 ||
            !itemsIDs.includes(item[parentKey])
          ) {
            result.push({
              ...item,
              level: 0,
              title: item.label || item.name || item.key,
              key: item.id,
              children: getChildren(items, item.id, 1, parentKey),
            });
          }
        });
        return result;
      }

      return json;
    } else {
      //Unknown error
      return rest.error(`API Server did not respond correctly`);
    }
  },

  error: (text) => {
    if (config.debug) {
      if (process.env.NODE_ENV !== "production") {
        message.warning(`API: ${text}`);
      }
      console.log(`%c API ERROR: ${text}`, "color: orangered");
    }
    return false;
  },

  log: (message) => {
    if (config.debug) console.log(`%c API: ${message}`, "color: blue");
  },

  getUUid: (d) => {
    let hashTime = new Date();
    d += hashTime.getTime();
    d = unescape(encodeURIComponent(d));
    return Sha256.hash(d);
  }

};

export default rest;
