import React, {useCallback, useEffect, useState} from "react";
import { ConfigProvider } from "antd";
import { IntlProvider, createIntl, createIntlCache} from "react-intl";
import { useSelector } from "react-redux";
import { ThemeProvider } from "styled-components";
import { ThroughProvider } from "react-through";
import themes from "@iso/config/theme/theme.config";
import AppLocale from "@iso/config/translation";
import Loader from "@iso/components/utility/loader";
import Context from "./Context";
import useWebSocket from 'react-use-websocket';
import accessToken from "@iso/lib/api/token";
//import {incidentHandler} from "@iso/lib/incidentHandler";
import api from "@iso/lib/api/rest";
import BrowserNotification from "./components/BrowserNotification";

export default function AppProviderInner({Auth, locale, children}) {

  const { themeName } = useSelector(
    (state) => state.ThemeSwitcher.changeThemes
  );

  const currentAppLocale = AppLocale[locale];
  let helpdeskURL;
  if(locale === 'ru'){
    helpdeskURL = 'https://helpdesk-ru.' + process.env.REACT_APP_DOMAIN;
  }else{
    helpdeskURL = 'https://helpdesk.' + process.env.REACT_APP_DOMAIN;
  }


  const cx = {
    api, helpdeskURL: helpdeskURL
  };

  return (
    <Context.Provider value={cx}>
      <ConfigProvider locale={currentAppLocale.antd}>
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}
        >
          <ThemeProvider theme={themes[themeName]}>
            <ThroughProvider>
              {Auth.isPending ? <Loader /> : children}
            </ThroughProvider>
          </ThemeProvider>
        </IntlProvider>
      </ConfigProvider>
    </Context.Provider>
  );
}
