const actions = {
    SET_GROUP_MAP: "SET_GROUP_MAP",

    setGroupMap: (usersGeo) => ({
        type: actions.SET_GROUP_MAP,
        payload: usersGeo
    }),

};
export default actions;
