import api from "@iso/lib/api/rest";
import authActions from "../auth/actions";
import { message } from "antd";

const actions = {
    TASKS_SAVE: "TASKS_SAVE",
    ALARMS_SAVE: "ALARMS_SAVE",
    PROFILE_SAVE_BEGIN: "PROFILE_SAVE_BEGIN",
    PROFILE_SAVE_SUCCESS: "PROFILE_SAVE_SUCCESS",
    PROFILE_SAVE_ERROR: "PROFILE_SAVE_ERROR",

    tasksSave: (values = {}, Auth = {}, intl = {}) => {

        return async (dispatch) => {
            dispatch(actions.profileSaveBegin());
            const formatted = toBinary(values.values, { block: 'tasks' });
            console.log(values);
            // console.log(formatted);
            // console.log(values.radius);
            dispatch(actions.profileSaveSuccess(intl));
        }
    },

    profileSaveSuccess: (intl) => {
        if (intl.formatMessage) {
            message.success(
                intl.formatMessage({
                    id: "MyProfile.saveSuccess",
                })
            );
        }
        return { type: actions.PROFILE_SAVE_SUCCESS };
    },
    profileSaveError: (intl, errorMessage = "") => {
        if (intl.formatMessage) {
            message.error(
                intl.formatMessage({
                    id: "MyProfile.saveError",
                }) +
                ": " +
                errorMessage
            );
        }
        return { type: actions.PROFILE_SAVE_ERROR };
    },
    profileSaveBegin: () => {
        return { type: actions.PROFILE_SAVE_BEGIN };
    },
};
export default actions;

const toBinary = (values, data, anyVariable) => {
    let binary_string = undefined;
    let binaryArray = [0, 0, 0, 0, 0, 0, 0];
    let binary_number = '';
    let any = anyVariable;

    switch (data.block) {
        case 'tasks':
            for (let i = 0; i <= values.length; i++) {
                if (values[i] === 'Family') {
                    binaryArray[6] = 1;
                }
                else if (values[i] === 'Friends') {
                    binaryArray[5] = 1;
                }
                else if (values[i] === 'Colleagues') {
                    binaryArray[4] = 1;
                }
                else if (values[i] === 'Second circle') {
                    binaryArray[2] = 1;
                }
                else if (values[i] === 'Third circle') {
                    binaryArray[1] = 1;
                }
                else if (values[i] === 'Fourth circle') {
                    binaryArray[0] = 1;
                }
            }
            if (values.includes('Family') && values.includes('Friends') && values.includes('Colleagues')) {
                binaryArray[3] = 1;
            }

            for (let i = 0; i < binaryArray.length; i++) {
                binary_number += binaryArray[i];
            }

            binary_string = parseInt(binary_number, 2);

            return binary_string;

        case 'alarm':
            for (let i = 0; i <= values.length; i++) {
                if (values[i] === 'Second circle') {
                    binaryArray[6] = 1;
                }
                else if (values[i] === 'Third circle') {
                    binaryArray[5] = 1;
                }
                else if (values[i] === 'Fourth circle') {
                    binaryArray[4] = 1;
                }
                else if (values[i] === 'Family') {
                    binaryArray[2] = 1;
                }
                else if (values[i] === 'Friends') {
                    binaryArray[1] = 1;
                }
                else if (values[i] === 'Colleagues') {
                    binaryArray[0] = 1;
                }
            }

            if (values.includes('Family') && values.includes('Friends') && values.includes('Colleagues')) {
                binaryArray[7] = 1;
            }

            if (any === 'any') {
                binaryArray[3] = 1;
            }

            for (let i = 0; i < binaryArray.length; i++) {
                binary_number += binaryArray[i];
            }

            binary_string = parseInt(binary_number, 2);

            return binary_string;

        default:
            return binary_string;
    }
}