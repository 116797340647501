import React, {useCallback, useEffect, useState} from "react";
import { ConfigProvider } from "antd";
import { IntlProvider, createIntl, createIntlCache} from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { ThemeProvider } from "styled-components";
import { ThroughProvider } from "react-through";
import themes from "@iso/config/theme/theme.config";
import AppLocale from "@iso/config/translation";
import Loader from "@iso/components/utility/loader";
import Context from "./Context";
import useWebSocket from 'react-use-websocket';
import accessToken from "@iso/lib/api/token";

import api from "@iso/lib/api/rest";
import BrowserNotification from "./components/BrowserNotification";
import AppProviderInner from "./AppProviderInner";
import AppProviderInnerAlt from "./AppProviderInnerAlt";
import actions from "@iso/redux/languageSwitcher/actions";
import Glide from "@glidejs/glide";
const { changeLanguage } = actions;

export default function AppProvider({ children }) {
  const { locale } = useSelector((state) => state.LanguageSwitcher.language);
  const Auth = useSelector((state) => state.Auth);
  const dispatch = useDispatch();

  useEffect(() => {
    let langs = ['ru', 'zh', 'es', 'pt'];

    let pathData = window.location.pathname.split('/');
    if(pathData[1] && langs.indexOf(pathData[1]) > -1 && locale !== pathData[1]){
      dispatch(changeLanguage('russian'));
    }else if(locale !== 'en' && langs.indexOf(pathData[1]) === -1){
      dispatch(changeLanguage('english'));
    }
  }, []);

  if(!Auth.raw.session) return (
      <AppProviderInnerAlt locale={locale} Auth={Auth} children={children} />
  );

  return (
    <AppProviderInner locale={locale} Auth={Auth} children={children} />
  );
}
