import Enlang from "./entries/en-US";
import Rulang from "./entries/ru-RU";
import Zhlang from "./entries/zh-CN";
import Eslang from "./entries/es-ES";
import Ptlang from "./entries/pt-PT";

const AppLocale = {
  en: Enlang,
  ru: Rulang,
  zh: Zhlang,
  es: Eslang,
  pt: Ptlang
};

export default AppLocale;
