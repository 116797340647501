import actions from "./actions";

const defalutState = {
    user: {
        id: 818923891,
        name: 'skeleton',
        geo: false,
        relation_name: 'Brother',
        fyid: 'fy99999999999999999999999',
        reputation: 115,
        relation: [],
        skills: [
            {
                id: 1,
                label: 'Driver',
            },
            {
                id: 2,
                label: 'Artist',
            },
            {
                id: 3,
                label: 'Doctor',
            }
        ],
        avatar: 'https://www.gannett-cdn.com/presto/2021/03/15/NABJ/bed373b6-58db-41d0-8c1c-f5dc3643394d-johndoebarrel.jpg?width=1200&disable=upscale&format=pjpg&auto=webp',
        ownSharesGeo: false,
    },
    users: [
        {
            id: 952181,
            name: 'John Doe',
            geo: false,
            relation_name: 'Brother',
            fyid: 'fy99999999999999999999999',
            reputation: 115,
            relation: [],
            skills: [
                {
                    id: 1,
                    label: 'Driver',
                },
                {
                    id: 2,
                    label: 'Artist',
                },
                {
                    id: 3,
                    label: 'Doctor',
                }
            ],
            avatar: 'https://www.gannett-cdn.com/presto/2021/03/15/NABJ/bed373b6-58db-41d0-8c1c-f5dc3643394d-johndoebarrel.jpg?width=1200&disable=upscale&format=pjpg&auto=webp'
        }
    ],
    drawer: {
        disableSwipe: false
    },
    distance: {
        length: 0,
        time: 0
    },
    coords: {
        lat: null,
        lng: null,
    }
}


export default function relationReducer(state = defalutState, action) {

    switch (action.type) {
        case actions.SET_USER:
            return {
                ...state,
                user: action.user
            };
        case actions.SET_USERS_LIST:
            return {
                ...state,
                users: action.users
            };
        case actions.DELETE_SINGLE_USERS_LIST:
            return {
                ...state,
                users: state.users.filter(elem => elem === action.user)
            };
        case actions.TOGGLE_DRAWER:
            return {
                ...state,
                drawer: {
                    disableSwipe: (!state.drawer.disableSwipe)
                }
            };
        case actions.SET_DISTANCE:
            return {
                ...state,
                distance: action.payload
            };
        case actions.SET_USER_COORDS:
            return {
                ...state,
                coords: action.payload
            };
        default:
            return state;
    }
}