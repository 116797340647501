export default function getChildren(
  items = [],
  seekId,
  level,
  parentKey = "parent"
) {
  let children = [];

  items.forEach((item) => {
    if (item[parentKey] === seekId) {
      children.push({
        ...item,
        level: level,
        title: item.label || item.name || item.key,
        key: item.id,
        children: getChildren(items, item.id, level + 1, parentKey),
      });
    }
  });
  return children;
}
