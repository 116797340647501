import antdPt from 'antd/lib/locale-provider/pt_PT'
import ptMessages from '../locales/pt_PT.json';
import ptMessagesFront from '../locales/pt_PT_front.json';


const PtLang = {
  messages: {
    ...ptMessages, ...ptMessagesFront
  },
  antd: antdPt,
  locale: 'pt-PT',
};
export default PtLang;