const actions = {
    OPEN_MAP_TOOLS: "OPEN_MAP_TOOLS",
    SET_CHAT: "SET_CHAT",
    SET_INCIDENTS: "SET_INCIDENTS",
    openMapTools: () => ({
        type: actions.OPEN_MAP_TOOLS,
    }),
    setChat: (chat) => ({
        type: actions.SET_CHAT,
        payload: chat
    }),
    setIncidents: (incidents) => ({
        type: actions.SET_INCIDENTS,
        payload: incidents
    })
};
export default actions;
