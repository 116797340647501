import zhMessages from '../locales/zh_CN.json';
import zhMessagesFront from '../locales/zh_CN_front.json';

const ZhLang = {
  messages: {
    ...zhMessages, ...zhMessagesFront
  },
  antd: null,
  locale: 'zh-CN',
};
export default ZhLang;
