import actions from "./actions";
import _ from 'lodash';

const initState = {
    groupMap: []
};

export default function perimitersReducer(state = initState, action) {
    switch (action.type) {
        case actions.SET_GROUP_MAP:
            return {
                ...state,
                groupMap: action.payload
            };
        default:
            return state;
    }
}
