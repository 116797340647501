export default {
  debug: true,
  tokenStorageName: "apostolIdToken",
  tokenExpireGapMin: 20,
  tokenCheckInterval: 1000 * 60 * 5,
  tokenFetchPath: process.env.REACT_APP_HOST_TOKEN,

  oAuth2RedirectUrl: process.env.REACT_APP_HOST_CODE,
  oAuthFormRedirect: "/oauth/",
  oAuth2TokenPath: "/oauth2/token",
  oAuth2AccessTokenStorage: "fenomyAccessToken",
  oAuth2RefreshTokenStorage: "fenomyRefreshToken",

  apiDomain: process.env.REACT_APP_HOST,
  apiPath: "/api/v1",
  clientId: "web-fenomy.com",
  clientSecret: "ziXugsqYWG0J7w0PRF6ztbXD",
};
