import actions from "./actions";

const initState = {
  isPending: false,
  error: false,
  success: false,
};
export default function profileReducer(state = initState, action) {
  switch (action.type) {
    case actions.PROFILE_SAVE_BEGIN:
      return {
        ...state,
        isPending: true,
        error: false,
        success: false,
      };
    case actions.PROFILE_SAVE_SUCCESS:
      return {
        ...state,
        isPending: false,
        error: false,
        success: true,
      };
    case actions.PROFILE_SAVE_ERROR:
      return {
        ...state,
        isPending: false,
        error: true,
        success: false,
      };
      case actions.PROFILE_SAVE_UPDATE: {
        return {
          ...state,
          
        }
      }
    default:
      return state;
  }
}
