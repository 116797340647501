import api from "@iso/lib/api/rest";
import authActions from "../auth/actions";
import { message } from "antd";

const actions = {
    PROFILE_SAVE: "PROFILE_SAVE",
    PROFILE_SAVE_BEGIN: "PROFILE_SAVE_BEGIN",
    PROFILE_SAVE_SUCCESS: "PROFILE_SAVE_SUCCESS",
    PROFILE_SAVE_ERROR: "PROFILE_SAVE_ERROR",
    PROFILE_SAVE_UPDATE: "PROFILE_SAVE_UPDATE",

    profileSave: (values = {}, Auth = {}, intl = {}) => {

        return async (dispatch) => {
            dispatch(actions.profileSaveBegin());
            let phoneValue = '  ';
            if (values.phone == undefined || values.phone == '') {
            } else {
                phoneValue = values.phone;
            }
            const nameParts = [values.firstName, values.lastName];
            let data = {};
            let skill_data = {};
            let saveResult = {};
            let any = values.any;
            let servicestatus = undefined;
            let serviceflags = undefined;
            let alarmflags = undefined;
            let alarmstatus = undefined;
            let binaryArray = [0, 0, 0, 0, 0, 0, 0];
            let binary_number = '';
            let checkedValues = values.serviceflags;
            if (checkedValues != undefined) {
                for (let i = 0; i <= checkedValues.length; i++) {
                    if (checkedValues[i] == 'family') {
                        binaryArray[6] = 1;
                    }
                    else if (checkedValues[i] == 'friends') {
                        binaryArray[5] = 1;
                    }
                    else if (checkedValues[i] == 'colleagues') {
                        binaryArray[4] = 1;
                    }
                    else if (checkedValues[i] == 'secondRound') {
                        binaryArray[2] = 1;
                    }
                    else if (checkedValues[i] == 'thirdRound') {
                        binaryArray[1] = 1;
                    }
                    else if (checkedValues[i] == 'fourthRound') {
                        binaryArray[0] = 1;
                    }
                }
                if (checkedValues.includes('family') && checkedValues.includes('friends') && checkedValues.includes('colleagues')) {
                    binaryArray[3] = 1;
                }
                for (let i = 0; i < binaryArray.length; i++) {
                    binary_number += binaryArray[i];
                }
                serviceflags = parseInt(binary_number, 2);
            }

            let binaryArrayFlags = [0, 0, 0, 0, 0, 0, 0];
            let binary_numberFlags = '';
            checkedValues = values.servicestatus;
            if (checkedValues != undefined) {
                for (let i = 0; i <= checkedValues.length; i++) {
                    if (checkedValues[i] == 'family') {
                        binaryArrayFlags[6] = 1;
                    }
                    else if (checkedValues[i] == 'friends') {
                        binaryArrayFlags[5] = 1;
                    }
                    else if (checkedValues[i] == 'colleagues') {
                        binaryArrayFlags[4] = 1;
                    }
                    else if (checkedValues[i] == 'secondRound') {
                        binaryArrayFlags[2] = 1;
                    }
                    else if (checkedValues[i] == 'thirdRound') {
                        binaryArrayFlags[1] = 1;
                    }
                    else if (checkedValues[i] == 'fourthRound') {
                        binaryArrayFlags[0] = 1;
                    }
                }
                if (checkedValues.includes('family') && checkedValues.includes('friends') && checkedValues.includes('colleagues')) {
                    binaryArrayFlags[3] = 1;
                }
                for (let i = 0; i < binaryArrayFlags.length; i++) {
                    binary_numberFlags += binaryArrayFlags[i];
                }
                servicestatus = parseInt(binary_numberFlags, 2);
            }

            let binaryArrayAlarmFlags = [0, 0, 0, 0, 0, 0, 0, 0];
            let binary_numberAlarmFlags = '';

            checkedValues = values.alarmflags;
            if (checkedValues != undefined) {
                for (let i = 0; i <= checkedValues.length; i++) {
                    if (checkedValues[i] == 'secondRound') {
                        binaryArrayAlarmFlags[6] = 1;
                    }
                    else if (checkedValues[i] == 'thirdRound') {
                        binaryArrayAlarmFlags[5] = 1;
                    }
                    else if (checkedValues[i] == 'fourthRound') {
                        binaryArrayAlarmFlags[4] = 1;
                    }
                    else if (checkedValues[i] == 'family') {
                        binaryArrayAlarmFlags[2] = 1;
                    }
                    else if (checkedValues[i] == 'friends') {
                        binaryArrayAlarmFlags[1] = 1;
                    }
                    else if (checkedValues[i] == 'colleagues') {
                        binaryArrayAlarmFlags[0] = 1;
                    }
                }
                if (checkedValues.includes('family') && checkedValues.includes('friends') && checkedValues.includes('colleagues')) {
                    binaryArrayAlarmFlags[7] = 1;
                }
                if (any == 'any') {
                    binaryArrayAlarmFlags[3] = 1;
                }
                for (let i = 0; i < binaryArrayAlarmFlags.length; i++) {
                    binary_numberAlarmFlags += binaryArrayAlarmFlags[i];
                }
                alarmflags = parseInt(binary_numberAlarmFlags, 2);
            }
            let binaryArrayAlarmStatus = [0, 0, 0, 0, 0, 0, 0, 0];
            let binary_numberAlarmStatus = '';
            checkedValues = values.alarmstatus;
            if (checkedValues != undefined) {
                for (let i = 0; i <= checkedValues.length; i++) {
                    if (checkedValues[i] == 'secondRound') {
                        binaryArrayAlarmStatus[6] = 1;
                    }
                    else if (checkedValues[i] == 'thirdRound') {
                        binaryArrayAlarmStatus[5] = 1;
                    }
                    else if (checkedValues[i] == 'fourthRound') {
                        binaryArrayAlarmStatus[4] = 1;
                    }
                    else if (checkedValues[i] == 'family') {
                        binaryArrayAlarmStatus[2] = 1;
                    }
                    else if (checkedValues[i] == 'friends') {
                        binaryArrayAlarmStatus[1] = 1;
                    }
                    else if (checkedValues[i] == 'colleagues') {
                        binaryArrayAlarmStatus[0] = 1;
                    }
                }
                if (checkedValues.includes('family') && checkedValues.includes('friends') && checkedValues.includes('colleagues')) {
                    binaryArrayAlarmStatus[7] = 1;
                }
                for (let i = 0; i < binaryArrayAlarmStatus.length; i++) {
                    binary_numberAlarmStatus += binaryArrayAlarmStatus[i];
                }
                alarmstatus = parseInt(binary_numberAlarmStatus, 2);
            }
            if (Auth.id) {
                let map = values.myskills;
                let my_skills = [];
                if (map != undefined) {
                    for (var i in Auth.raw.participant.skills) {
                        if (!map.includes(Auth.raw.participant.skills[i].id)) {
                            skill_data = {
                                id: Auth.id,
                                skill: Auth.raw.participant.skills[i].id,
                            };
                            api.q("/participant/skill/delete", skill_data, { exact: true });
                        }
                    }

                    for (var i in map) {
                        skill_data = {
                            id: Auth.id,
                            skill: map[i],
                        };
                        api.q("/participant/skill/set", skill_data, { exact: true });
                    }

                }
                data = {
                    id: Auth.id,
                    email: [values.email],
                    birthday: values.birthday,
                    serviceflags: serviceflags,
                    servicestatus: servicestatus,
                    alarmflags: alarmflags,
                    alarmstatus: alarmstatus,
                    alarmradius: values.alarmradius,
                    serviceradius: values.serviceradius,
                    phone: [phoneValue],

                    name: {
                        first: values.firstName,
                        last: values.lastName,
                        name: nameParts.join(" "),
                        short: nameParts.join(" "),
                    },
                };
                saveResult = await api.q("/participant/set", data, { exact: true });
                let extFlags = '000000000000000'.split('');
                if (values.alarmstatus.indexOf('peopleDispatcher') > -1) {
                    extFlags[extFlags.length - 1] = 1;
                }
                if (values.alarmflags.indexOf('peopleDispatcher') > -1) {
                    extFlags[extFlags.length - 2] = 1;
                }
                if (values.publicReputation) {
                    extFlags[extFlags.length - 4] = 1;
                }
                extFlags = extFlags.join('');

                let digit = parseInt(extFlags, 2);
                await api.q("/participant/set", {
                    "id": Auth.raw.participant.id,
                    "extflags": digit
                });
            } else {
                data = {
                    username: Auth.raw.profile.username,
                    email: values.email,
                    phone: phoneValue,
                    birthday: values.birthday,
                    name: values.name,
                    serviceflags: serviceflags,
                    servicestatus: servicestatus,
                    alarmflags: alarmflags,
                    alarmstatus: alarmstatus,
                    alarmradius: values.alarmradius,
                    serviceradius: values.serviceradius,
                };


                saveResult = await api.q("/user/set", data, { exact: true });
            }
            if (saveResult.hasOwnProperty("error")) {
                dispatch(actions.profileSaveError(intl, saveResult.error.message));
                return;
            }

            dispatch(actions.profileSaveSuccess(intl));
            dispatch(authActions.authCheck());
        };
    },

    profileSaveSuccess: (intl) => {
        if (intl.formatMessage) {
            message.success(
                intl.formatMessage({
                    id: "MyProfile.saveSuccess",
                })
            );
        }
        return { type: actions.PROFILE_SAVE_SUCCESS };
    },
    profileSaveError: (intl, errorMessage = "") => {
        if (intl.formatMessage) {
            message.error(
                intl.formatMessage({
                    id: "MyProfile.saveError",
                }) +
                ": " +
                errorMessage
            );
        }
        return { type: actions.PROFILE_SAVE_ERROR };
    },
    profileSaveBegin: () => {
        return { type: actions.PROFILE_SAVE_BEGIN };
    },
    profileSaveUpdate: () => {
        return { type: actions.PROFILE_SAVE_UPDATE }
    }
};
export default actions;
