const actions = {
    GET_ACTIVE_GROUPS: "GET_ACTIVE_GROUPS",
    SET_CURRENT_GROUP: "SET_CURRENT_GROUP",
    SET_MEMBERS: 'SET_MEMBERS',
    SET_MESSAGES: 'SET_MESSAGES',
    SET_CURRENT_USER_STATUS: 'SET_CURRENT_USER_STATUS',

    getActiveGroups: (groups_list) => {
        return {
            type: actions.GET_ACTIVE_GROUPS,
            groups_list: groups_list
        }
    },

    setCurrentGroup: (group) => {
        return {
            type: actions.SET_CURRENT_GROUP,
            group: group
        }
    },

    setMembers: (members) => {
        return {
            type: actions.SET_MEMBERS,
            members: members
        } 
    },
    
    setMessages: (messages) => {
        return {
            type: actions.SET_MESSAGES,
            members: messages
        } 
    },

    setUserStatus: (status) => {
        return {
            type: actions.SET_MEMBERS,
            status: status
        } 
    },


};

export default actions;
