import actions from "./actions";

const initState = {
    mapToolsOpened: false,
    chat: {
        id: null,
        messages: []
    },
    incidents: []
};

export default function (state = initState, action) {

    switch (action.type) {
        case actions.OPEN_MAP_TOOLS:
            return {
                ...state,
                mapToolsOpened: !state.mapToolsOpened,
            };
        case actions.SET_CHAT:
            return {
                ...state,
                chat: action.payload
            };
        case actions.SET_INCIDENTS:
            return {
                ...state,
                incidents: action.payload
            };
        default:
            return state;
    }
}
