import language from "@iso/config/language.config";

import englishLang from "@iso/assets/images/flag/uk.svg";
import russianLang from "@iso/assets/images/flag/russia.svg";

const storedLanguage = localStorage.getItem("feDefaultLang");
const languageIDs = [
  {
    languageId: "english",
    locale: "en",
  },
  {
    languageId: "russia",
    locale: "ru",
  },
  {
    languageId: "сhinese",
    locale: "zh",
  }
  ,
  {
    languageId: "spanish",
    locale: "es",
  },
  {
    languageId: "portuguese",
    locale: "pt",
  }
]
let initialLang = languageIDs.filter(elem => window.location.pathname.substring(0,4).includes(elem.locale))

initialLang = initialLang.length == 0 ? language : initialLang[0].languageId

const config = {
  defaultLanguage: initialLang,
  options: [
    {
      languageId: "russia",
      locale: "ru",
      text: "Русский",
      icon: russianLang,
      view: "РУС",
    },
    {
      languageId: "english",
      locale: "en",
      text: "English",
      icon: englishLang,
      view: "EN",
    },
    {
      languageId: "сhinese",
      locale: "zh",
      text: "Chinese",
      icon: englishLang,
      view: "中文",
    }
    ,
    {
      languageId: "spanish",
      locale: "es",
      text: "Spanish",
      icon: englishLang,
      view: "ES",
    },
    {
      languageId: "portuguese",
      locale: "pt",
      text: "Portuguese",
      icon: englishLang,
      view: "PT",
    }
  ],
};

export function getCurrentLanguage(lang) {
  let selecetedLanguage = config.options[0];
  config.options.forEach((language) => {
    if (language.languageId === lang || language.locale === lang) {
      selecetedLanguage = language;
    }
  });
  return selecetedLanguage;
}
export default config;
